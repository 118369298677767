@alien: "\f075";
@celsius: "\f03c";
@fahrenheit: "\f045";
@degrees: "\f042";
@thermometer: "\f055";
@thermometer-exterior: "\f053";
@thermometer-internal: "\f054";
@cloud-down: "\f03d";
@cloud-up: "\f040";
@cloud-refresh: "\f03e";
@horizon: "\f047";
@horizon-alt: "\f046";
@sunrise: "\f051";
@sunset: "\f052";
@moonrise: "\f0c9";
@moonset: "\f0ca";
@refresh: "\f04c";
@refresh-alt: "\f04b";
@umbrella: "\f084";
@barometer: "\f079";
@humidity: "\f07a";
@na: "\f07b";
@train: "\f0cb";