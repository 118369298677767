@import "variables.less";
@import "eliteadmin.less";
@import "widgets.less";
@import "sidebar-nav.less";
@import "icons/font-awesome/less/font-awesome.less";
@import "icons/themify-icons/themify-icons";
@import "icons/simple-line-icons/less/simple-line-icons";
@import "icons/weather-icons/less/weather-icons";
@import "icons/linea-icons/linea.less";
@import "pages.less";
@import "responsive.less";
// @import "dark.less";
@import "spinners.css";
@import "bootstrap-fix.less";
