.wi-alien:before {content: @alien;}
.wi-celsius:before {content: @celsius;}
.wi-fahrenheit:before {content: @fahrenheit;}
.wi-degrees:before {content: @degrees;}
.wi-thermometer:before {content: @thermometer;}
.wi-thermometer-exterior:before {content: @thermometer-exterior;}
.wi-thermometer-internal:before {content: @thermometer-internal;}
.wi-cloud-down:before {content: @cloud-down;}
.wi-cloud-up:before {content: @cloud-up;}
.wi-cloud-refresh:before {content: @cloud-refresh;}
.wi-horizon:before {content: @horizon;}
.wi-horizon-alt:before {content: @horizon-alt;}
.wi-sunrise:before {content: @sunrise;}
.wi-sunset:before {content: @sunset;}
.wi-moonrise:before {content: @moonrise;}
.wi-moonset:before {content: @moonset;}
.wi-refresh:before {content: @refresh;}
.wi-refresh-alt:before {content: @refresh-alt;}
.wi-umbrella:before {content: @umbrella;}
.wi-barometer:before {content: @barometer;}
.wi-humidity:before {content: @humidity;}
.wi-na:before {content: @na;}
.wi-train:before {content: @train;}