@time-1: "\f08a";
@time-2: "\f08b";
@time-3: "\f08c";
@time-4: "\f08d";
@time-5: "\f08e";
@time-6: "\f08f";
@time-7: "\f090";
@time-8: "\f091";
@time-9: "\f092";
@time-10: "\f093";
@time-11: "\f094";
@time-12: "\f089";