@cloud: "\f041";
@cloudy: "\f013";
@cloudy-gusts: "\f011";
@cloudy-windy: "\f012";
@fog: "\f014";
@hail: "\f015";
@rain: "\f019";
@rain-mix: "\f017";
@rain-wind: "\f018";
@showers: "\f01a";
@sleet: "\f0b5";
@snow: "\f01b";
@sprinkle: "\f01c";
@storm-showers: "\f01d";
@thunderstorm: "\f01e";
@snow-wind: "\f064";
@snow: "\f01b";
@smog: "\f074";
@smoke: "\f062";
@lightning: "\f016";
@raindrops: "\f04e";
@raindrop: "\f078";
@dust: "\f063";
@snowflake-cold: "\f076";
@windy: "\f021";
@strong-wind: "\f050";
@sandstorm: "\f082";
@earthquake: "\f0c6";
@fire: "\f0c7";
@flood: "\f07c";
@meteor: "\f071";
@tsunami: "\f0c5";
@volcano: "\f0c8";
@hurricane: "\f073";
@tornado: "\f056";
@small-craft-advisory: "\f0cc";
@gale-warning: "\f0cd";
@storm-warning: "\f0ce";
@hurricane-warning: "\f0cf";
@wind-direction: "\f0b1";